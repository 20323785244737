import React, { useState, useEffect } from "react";
import { Card, Spinner } from "react-bootstrap";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import S3Image from "../components/S3Image";

export default function UserDetail() {
  const location = useLocation();
  console.log(location);
  const item = location?.state;

  return (
    <>
      <div>
        <div className="page-titles">
          <h4>Users Details</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="user-view">Users</Link>
            </li>
            <li className="breadcrumb-item active d-flex align-items-center">
              <Link>Users Details</Link>
            </li>
          </ol>
        </div>
        <Card>
          <Card.Header>
            <div
              className="d-flex bio-user p-3"
              style={{ gap: "1rem", flexGrow: "1" }}
            >
              <div>
                <S3Image
                  imageKey={item?.profileImage}
                  imageStyle={"details-profile"}
                />
              </div>
              <div className="text-left" style={{ flex: "1" }}>
                <h3 className="text-color ">
                  {item?.firstName} <span>{item?.lastName}</span>
                </h3>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="m-0">Email</h5>
                  <p className="m-0">{item?.email}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="m-0">Created At</h5>
                  <p className="m-0">{moment(item?.createdAt).format("ll")}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="m-0">Phone</h5>
                  <p className="m-0">
                    {item?.countryCode} {item?.mobileNumber}
                  </p>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="bio-user">
              <h5 style={{ margin: "0", padding: "15px 10px 0px 10px " }}>
                Bio:
              </h5>
              <p style={{ marginLeft: "10px", textAlign: "start" }}>
                {" "}
                {item?.bio}
              </p>
            </div>
            <div
              style={{
                height: "1px",
                marginTop: "px",
                Width: "100%",
                opacity: ".5",
                backgroundColor: "#c6c9c9ee",
              }}
            ></div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
