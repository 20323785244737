import axios from "axios";
import axiosInstance from "../../../services/AxiosInstance";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// ===================getAPIS=============================
export async function dashboardApi() {
  const response = await axiosInstance.get("admin/userManage/dashboard", {});
  return response;
}

export async function userManagementList(currentPage, itemsPerPage, search) {
  // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const headers = {
    timezone: timeZone,
  };
  const response = await axiosInstance.get(
    `admin/userManage/getUsers?limit=${itemsPerPage}&page=${currentPage}&search=${search}`,
    { headers }
  );
  return response;
}

export async function blockUserApi(_id) {
  const data = { userId: _id };
  const response = await axiosInstance.put(`admin/userManage/userBlock`, data);
  return response;
}

export async function deleteUserApi(_id) {
  const response = await axiosInstance.delete(
    `admin/userManage/deleteUser?userId=${_id}`
  );
  return response;
}
