import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import defaultImage from "../../images/defaultDp.jpg";
// import { KEYCHAIN } from '../utils/keychain';

const S3Image = ({ imageKey, title, imageStyle }) => {
  const [imageData, setImageData] = useState(null);

  const onImageError = (e) => {
    e.target.src = defaultImage;
  };

  console.log(process.env.REACT_APP_BUCKET_NAME);
  useEffect(() => {
    const fetchImageData = async () => {
      if (!imageKey) {
        return;
      }
      try {
        const s3 = new AWS.S3();
        const params = {
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: imageKey,
        };
        const data = await s3.getObject(params).promise();
        const base64Image = data.Body.toString("base64");

        setImageData(base64Image);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImageData();
  }, [imageKey]);

  if (!imageData) {
    return (
      <div>
        <img height={60} width={60} src={defaultImage} className={imageStyle} />
      </div>
    );
  }

  return (
    <div>
      {title === "adDp" ? (
        <img
          width={120}
          height={120}
          style={{ borderRadius: "15px" }}
          className="text-center"
          src={`data:image/png;base64,${imageData}`}
          alt="S3 Content"
          onError={onImageError}
        />
      ) : (
        <>
          {title === "crousel" ? (
            <img
              height={60}
              width={60}
              style={{
                borderRadius: "10px",
                height: "400px",
                objectFit: "cover",
              }}
              className="d-block w-100"
              src={`data:image/png;base64,${imageData}`}
              alt="S3 Content"
              onError={onImageError}
            />
          ) : (
            <>
              {title === "modal" ? (
                <img
                  style={{ width: "68vw", height: "600px", objectFit: "cover" }}
                  src={`data:image/png;base64,${imageData}`}
                  alt="S3 Content"
                  onError={onImageError}
                />
              ) : (
                <img
                  height={60}
                  width={60}
                  className="profile-img"
                  src={`data:image/png;base64,${imageData}`}
                  alt="S3 Content"
                  onError={onImageError}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default S3Image;
