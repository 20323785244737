import React from "react";

export default function Filter({
  setSearch,
  setStartDate,
  setEndDate,
  searchType,
  dateType,
  filterType,
  setFilterType,
  FilterOption,
  limitValue,
}) {
  return (
    <div
      className="d-flex align-items-end justify-content-between "
      style={{ flexWrap: "wrap" }}
    >
      {searchType === "search" ? (
        <div
          className=""
          style={{
            width: "40%",
          }}
        >
          <div class="input-group ">
            <input
              type="text"
              id="search-box"
              className="form-control search-box"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {filterType === "filterType" ? (
        <div className="d-flex align-items-center" style={{ gap: "10px" }}>
          <label className="m-0">Limit</label>
          <select
            style={{ textAlign: "start", fontWeight: "bold" }}
            class="d-flex justify-content-start text-secondary border border-secondary btn btn-sm"
            onChange={(e) => setFilterType(e.target.value)}
            value={limitValue}
          >
            {FilterOption?.map((item) => (
              <option className="filter-options" value={item?.value} key={item}>
                {item?.label}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div></div>
      )}
      {dateType === "date" ? (
        <div className="d-flex date-filter" style={{ gap: "20px" }}>
          <div className="d-flex flex-column">
            <label>Start date</label>
            <input type="date" onChange={(e) => setStartDate(e.target.value)} />
          </div>
          <div className="d-flex flex-column">
            <label>End date</label>
            <input type="date" onChange={(e) => setEndDate(e.target.value)} />
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
