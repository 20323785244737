import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { userManagementList } from "..//components/APIs/Api";
import Lottie from "lottie-react";
import defaultImg from "../../images/defaultDp.jpg";
import animationData from "..//../images/loding.json";
import { variable, s3BaseUrl } from "../components/Variable";
import { blockUserApi, deleteUserApi } from "..//components/APIs/Api";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import Filter from "../components/Filter/Filter";
import { limitOptions } from "../components/Filter/FilterLable";
import Pagination from "../components/Common/Pagination";
import S3Image from "../components/S3Image";
import { useNavigate } from "react-router-dom";

export default function UserManagement() {
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [changeUserPassword, setChangeUserPassword] = useState(false);
  const [usersList, setUsersList] = useState();
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 600),
    []
  );
  //============usersList============
  async function userListData() {
    setLoader(true);
    const res = await userManagementList(currentPage, itemsPerPage, search);
    setUsersList(res);
    setLoader(false);
  }

  //=========pagination=============
  const totalItems = usersList?.data?.data?.countUser;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  //+++++++=blockedUsers==================
  async function blockUser(id) {
    try {
      const res = await blockUserApi(id);
      setLoader(false);
      userListData();
      toast.success(res?.data?.data?.updateUser);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.response?.data?.data
      );
    }
  }
  ///===========delete user======================
  async function handleDeleteUser(id) {
    try {
      const res = await deleteUserApi(id);
      userListData();
    } catch (error) {
      console.error("Something bad happened");
      console.error(error.response);
      throw new Error(
        error.response?.data?.message || error.response?.data?.data
      );
    }
  }

  function deleteUserPromise(id) {
    toast.promise(handleDeleteUser(id), {
      loading: "Deleting...",
      success: "Deleted successfully!",
      error: (err) => {
        return err.message || "Failed to delete. Please try again later.";
      },
    });
  }
  useEffect(() => {
    userListData();
    scrollToTop();
  }, [currentPage, search]);

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  return (
    <>
      <div>
        <PageTitle activeMenu="Users List" motherMenu="Users" />
        <div className="pb-4">
          <Filter searchType="search" setSearch={handleSearch} />
        </div>
        <Col>
          {loader ? (
            <div className="">
              <Lottie
                style={{ height: "200px", marginTop: "45px", padding: "30px" }}
                animationData={animationData}
              />
            </div>
          ) : (
            <Card>
              <Card.Body>
                {/* {loader ? (
                  <UserListingShimmerLoader className='table' />
                ) : ( */}
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>IMAGE</strong>
                      </th>

                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th>
                        <strong>EMAIL</strong>
                      </th>
                      <th>
                        <strong>PHONE NUMBER</strong>
                      </th>
                      {/* <th>
                        <strong>Updated Password</strong>
                      </th> */}
                      <th>
                        <strong>STATUS</strong>
                      </th>
                      <th>
                        <strong>ACTION</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList?.data?.data?.Users?.map((item, i) => (
                      <tr key={item.id}>
                        <td>
                          <S3Image
                            imageKey={item?.profileImage}
                            // imageStyle={"profile-img"}
                          />{" "}
                        </td>

                        <td>
                          {item?.firstName?.length > 15
                            ? item?.firstName.slice(0, 15) + "..."
                            : item?.firstName}{" "}
                          {item?.lastName?.length > 15
                            ? item?.lastName.slice(0, 15) + "..."
                            : item?.lastName}
                        </td>
                        {/* <td>{item.email}</td> */}
                        <td>
                          {item?.email?.length > 20
                            ? item?.email.slice(0, 20) + "..."
                            : item?.email}
                        </td>
                        <td>
                          {item.countryCode} {item.mobileNumber}
                        </td>

                        <td>
                          {item.isBlocked === false ? (
                            <span class="badge-success light badge">
                              Active
                            </span>
                          ) : (
                            <span class="badge-danger light badge">
                              Inactive
                            </span>
                          )}
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="info light"
                              className="light sharp btn btn-info i-false"
                            >
                              {svg1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  navigate("/user-details", { state: item });
                                }}
                              >
                                View Details
                              </Dropdown.Item>
                              {item.isBlocked === false ? (
                                <Dropdown.Item
                                  onClick={() => blockUser(item._id)}
                                >
                                  Inactive
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  onClick={() => blockUser(item._id)}
                                >
                                  Active
                                </Dropdown.Item>
                              )}

                              <Dropdown.Item
                                onClick={() => deleteUserPromise(item?._id)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {/* )} */}
                {usersList?.data?.data.total === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {usersList?.data?.data?.countUser !== 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Total Users{" "}
                      <span
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        {totalItems}
                      </span>
                    </div>

                    <Pagination
                      pageCount={totalPages}
                      pageValue={currentPage}
                      setPage={setCurrentPage}
                    />
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
      </div>
    </>
  );
}
